<template>
  <div>
    <a-spin v-show="!showModal" :spinning="loading">
      <Header style="margin-bottom: 20px;" title="兑换卡列表" @back="onBack(false)"></Header>
      <a-spin :spinning="loading">
        <a-form ref="form" :model="formState" name="form" @finish="onSearch">
          <!-- 搜索栏 -->
          <a-row>
            <a-form-item class="ui-form__item" name="cardNo" label="一卡通卡号">
              <a-input v-model:value="formState.cardNo" placeholder="请输入一卡通卡号" />
            </a-form-item>

            <a-form-item class="ui-form__item" name="userInfo" label="领取用户信息">
              <a-input v-model:value="formState.userInfo" placeholder="请输入领取用户信息" />
            </a-form-item>

            <a-form-item class="ui-form__item" name="isDisabled" label="状态">
              <a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 180px;">
                <a-select-option :value="0">启用</a-select-option>
                <a-select-option :value="1">禁用</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item class="ui-form__item" name="archiveStatus" label="是否领取">
              <a-select v-model:value="formState.archiveStatus" placeholder="请选择是否领取" allow-clear style="width: 180px;">
                <a-select-option :value="1">是</a-select-option>
                <a-select-option :value="0">否</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="领取时间" name="archiveTime" class="ui-form__item">
              <a-range-picker v-model:value="formState.archiveTime"></a-range-picker>
            </a-form-item>
          </a-row>

          <a-row>
            <a-col :span="18">
				<exportReport type="unionCardArchiveCodeExportStrategy" :searchData="{recordId: id, ...searchData}"></exportReport>
            </a-col>
            <a-col :span="6" style="text-align: right">
              <a-button
                  style="margin-right: 10px"
                  type="primary"
                  html-type="submit"
              >搜索</a-button
              >
              <a-button @click="reset">重置</a-button>
            </a-col>
          </a-row>
        </a-form>

        <div style="margin-top: 20px;">
          <a-table :pagination="pagination" :columns="columns" :dataSource="list" :rowKey="record => record.key"
                   :rowSelection="{ type: rowSelectionType, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
                   :scroll="{ x: 1250 }">
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'archiveStatus'">
                {{ record.archiveStatus ? '是' : '否' }}
              </template>
              <template v-if="column.key === 'archiveTime'">
                {{ transDateTime(record.archiveTime, 1) }}
              </template>
              <template v-if="column.key === 'archiveInfo'">
                <div v-if="record.archiveUserId">ID：{{ record.archiveUserId }}</div>
                <div v-if="record.archiveUserPhone">手机号：{{ record.archiveUserPhone }}</div>
                <div v-if="!record.archiveUserId && !record.archiveUserPhone">-</div>
              </template>
              <template v-if="column.key === 'isDisabled'">
                {{ record.isDisabled ? '已禁用' : '已启用' }}
              </template>
              <template v-if="column.key === 'action'">
                <a-dropdown :trigger="['click', 'hover']">
                  <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                  </a-button>
                  <template #overlay>
                    <a-menu>
                      <div v-permission="['coupon_preferential_record_detailOpen']" v-if="record.isDisabled" @click="onDisabled(record)">
                        <a-menu-item>
                          启用
                        </a-menu-item>
                      </div>
                      <div v-permission="['coupon_preferential_record_detailClose']" v-else @click="onDisabled(record)">
                        <a-menu-item>
                          禁用
                        </a-menu-item>
                      </div>
                    </a-menu>
                  </template>
                </a-dropdown>
              </template>
            </template>
          </a-table>
        </div>
      </a-spin>
    </a-spin>
  </div>
</template>

<script>
import Header from '@/components/header/header.vue';
import exportReport from '@/components/exportReport/exportReport.vue';
import {
  Icon
} from '@/components/icon/icon.js';
import { getUnionCardArchiveCodeList, unionCardArchiveCodeUpdateStatus } from "@/service/modules/coupon";
export default {
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  components: {
    Icon,
    Header,
	exportReport
  },
  data() {
    return {
      showModal: false,
      loading: false,
      rowSelectionType: 'checkbox',
      selectedRowKeys: [],
      formState: {

      },
      columns: [{
        title: '一卡通卡号',
        dataIndex: 'cardNo',
      }, {
        title: '卡密',
        dataIndex: 'cardPass',
      }, {
        title: '是否领取',
        key: 'archiveStatus',
      }, {
        title: '领取时间',
        key: 'archiveTime',
      }, {
        title: '领取用户信息',
        key: 'archiveInfo',
      }, {
        title: '状态',
        key: 'isDisabled',
      }, {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 150
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.onSearch();
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onBack(isRef) {
      this.$emit('back', isRef);
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    async getData() {
      if(this.formState.archiveTime && this.formState.archiveTime.length === 2) {
        this.searchData.archiveStartTime = this.moment(this.formState.archiveTime[0].startOf('day')).unix();
        this.searchData.archiveEndTime = this.moment(this.formState.archiveTime[1].endOf('day')).unix();
      }
      // delete this.searchData.archiveTime;
      this.loading = true;
      try {
        let ret = await getUnionCardArchiveCodeList({
          recordId: this.id,
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onDisabled(item) {
      this.$confirm({
        title: '提示',
        content: '确认' + (item.isDisabled ? '启用' : '禁用') + '该生成记录吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await unionCardArchiveCodeUpdateStatus({
              id: item.id,
              isDisabled: item.isDisabled ? 0 : 1
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    onSelectChange(record, selected) {
      if (this.rowSelectionType === 'checkbox') {
        if (selected) {
          // this.selectedRowData.push(JSON.parse(JSON.stringify(record)));
          this.selectedRowKeys.push(record.id);
        } else {
          this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
          // this.selectedRowData = this.selectedRowData.filter(item => {
          // 	return item.id !== record.id;
          // });
        }
      }
    },
    onSelectAll(selected) {
      if (selected) {
        this.peopleList.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) === -1) {
            // this.selectedRowData.push(JSON.parse(JSON.stringify(item)));
            this.selectedRowKeys.push(item.id);
          }
        })
      } else {
        this.peopleList.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) !== -1) {
            // this.selectedRowData.splice(this.selectedRowKeys.indexOf(item.id), 1);
            this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
          }
        })
      }
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
